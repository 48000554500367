import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
      metaTags: [
        {
          name: 'description',
          content: 'Presentación de mi persona: quién soy, resumen de experiencia laboral y tecnologías de desarrollo web utilizadas.'
        },
        {
          property: 'og:description',
          content: 'Presentación de mi persona: quién soy, resumen de experiencia laboral y tecnologías de desarrollo web utilizadas.'
        },
        {
          property: 'og:title',
          content: 'Portafolio - Resumen'
        },
        {
          property: 'og:image',
          content: 'https://i.imgur.com/yZIjLEl.png'
        }
      ]
    }
  },
  {
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/ProjectsView.vue')
  },
  {
    path: '/project/1',
    name: 'project1',
    component: () => import(/* webpackChunkName: "project1" */ '../views/Project1View.vue')
  },
  {
    path: '/project/2',
    name: 'project2',
    component: () => import(/* webpackChunkName: "project2" */ '../views/Project2View.vue')
  },
  {
    path: '/project/3',
    name: 'project3',
    component: () => import(/* webpackChunkName: "project3" */ '../views/Project3View.vue')
  },
  {
    path: '/project/4',
    name: 'project4',
    component: () => import(/* webpackChunkName: "project4" */ '../views/Project4View.vue')
  },
  {
    path: '/project/5',
    name: 'project5',
    component: () => import(/* webpackChunkName: "project5" */ '../views/Project5View.vue')
  },
  {
    path: '/project/6',
    name: 'project6',
    component: () => import(/* webpackChunkName: "project6" */ '../views/Project6View.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
