<template>
  <div>
    <Header activeTab="index"></Header>

    <div class="tile is-parent has-text-centered">
      <div class="tile is-child box notification is-info">
        <p class="title is-size-2">Erick Castillo B.</p>
      </div>
    </div>
    <div class="has-text-centered" style="height: 40px;">
      <a href="https://github.com/ecastillob" style="padding-right: 30px;">
        <button class="button is-small is-warning">
          <span class="icon is-small">
            <i class="fab fa-github"></i>
          </span>
          <span>GitHub</span>
        </button>
      </a>
      <a href="https://www.linkedin.com/in/ecastillob1/">
        <button class="button is-small is-warning">
          <span class="icon is-small">
            <i class="fab fa-linkedin"></i>
          </span>
          <span>Linkedin</span>
        </button>
      </a>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent has-text-centered">
        <div class="tile is-child box">
          <p class="title">Educación</p>
          <p class="subtitle">Ingeniero en Computación</p>
        </div>
      </div>
      <div class="tile is-parent has-text-centered">
        <div class="tile is-child box">
          <p class="title is-success">Seniority</p>
          <p class="subtitle">Desarrollador web SSr <br>Data Engineer Jr</p>
        </div>
      </div>
      <div class="tile is-parent has-text-centered">
        <div class="tile is-child box">
          <p class="title is-success">Experiencia</p>
          <p class="subtitle">
            5 años trabajando con Python <br>
            <ul style="font-size: 15px">
              <li>4 años en desarrollo web</li>
              <li>1 año en ingeniería de datos y machine learning</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <!--
    <div class="tile is-parent has-text-centered">
      <div class="tile is-child box notification is-info is-light">
        <p class="title is-size-4">Actualmente trabajando para <a href="#" target="_blank">...</a></p>
      </div>
    </div>
    -->
    <div class="tile is-parent has-text-centered">
      <div class="tile is-child box">
        <p class="title is-success">Tecnologías</p>
        <div class="flex-container">
          <img src="https://i.imgur.com/lHOUNHD.png" title="Python" loading="lazy">

          <img src="https://i.imgur.com/mXWGDbS.png" title="Poetry" loading="lazy">

          <img src="https://i.imgur.com/B5rKOJf.png" title="Docker" loading="lazy">
          
          <!-- <img src="https://git-scm.com/images/logos/downloads/Git-Logo-2Color.png" title="Git"> -->
          <img src="https://i.imgur.com/NcGpZT6.png" title="Git" loading="lazy">

          <img src="https://i.imgur.com/cJj5yoa.png" title="Pre-commit" loading="lazy">

          <img src="https://i.imgur.com/IZ5kEE4.png" title="Github" loading="lazy">

          <img src="https://i.imgur.com/TMMz528.png" title="CI & CD" loading="lazy">
        </div>
        <div class="flex-container">
          <!-- <img src="https://static.djangoproject.com/img/logos/django-logo-negative.png" title="Django"> -->
          <img src="https://i.imgur.com/8Q0hqsH.png" title="Django" loading="lazy">
          
          <!-- <img src="https://www.django-rest-framework.org/img/logo.png" title="Django REST Framework"> -->
          <img src="https://i.imgur.com/GTr7Xvr.png" title="Django REST Framework" loading="lazy">
          
          <img src="https://i.imgur.com/DIGlJQO.png" title="PostgreSQL" loading="lazy">
          
          <!-- <img src="https://download.osgeo.org/postgis/logo_suite/adbadge_wide/adbadge_wide.png" title="PostGIS"> -->
          <img src="https://i.imgur.com/8wJQvPv.png" title="PostGIS" loading="lazy">
          
          <!-- <a href="#"><img src="https://i.imgur.com/b8RCPaZ.jpg" title="Vuetify"></a> -->

          <!-- <img src="https://bulma.io/images/bulma-logo.png" title="Bulma CSS"> -->
          <img src="https://i.imgur.com/S0y13Ye.png" title="Bulma CSS" loading="lazy">

          <img src="https://i.imgur.com/zJ0gDIS.png" title="Vue" loading="lazy">

          <!-- <a href="#"><img src="https://buefy.org/static/img/buefy.1d65c18.png" alt=""></a> -->
          <img src="https://i.imgur.com/SBk5Mta.png" title="Buefy" loading="lazy">
        </div>
        <div class="flex-container">
          <img src="https://i.imgur.com/i0NEagT.png" title="Airflow" loading="lazy">

          <img src="https://i.imgur.com/raQqZkl.png" title="AWS" loading="lazy">

          <img src="https://i.imgur.com/NYvLkYQ.png" title="Numpy" loading="lazy">

          <img src="https://i.imgur.com/uyxnKAf.png" title="Pandas" loading="lazy">

          <img src="https://i.imgur.com/17zN4Ki.png" title="Scikit-learn" loading="lazy">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  data: function(){
    return {
      loading: false,
    }
  },
  mounted(){
    fetch(`${process.env.VUE_APP_BASE_API_URL}/portfolio/`)
    .then()
    .catch(error => {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
    })
  },
}
</script>
